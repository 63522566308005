
import { DateTime } from 'luxon'

export default {
  props: {
    order_events: { type: Array }
    provider: { type: String }
    requires_shipping: { type: Boolean }
  }

  computed: {
    events: ->
      events = {}
      events[event.name] = event.timestamp for event in @order_events
      events

    timeline: ->
      timeline = []
      timeline.push(@build_payment_event()) if @requires_online_payment
      timeline.push(@build_order_received_event())
      timeline.push(@build_preparing_event())
      timeline.push(@build_ready_for_dispatch_event())
      timeline.push(@build_dispatched_event()) if @requires_shipping
      timeline.push(@build_complete_event())
      timeline

    requires_online_payment: ->
      @provider.toLowerCase() in ['paygate', 'offline_eft', 'moya', 'yoco']
  }

  methods: {
    build_payment_event: ->
      {
        icon: "fas #{!!@events.payment_received && 'fa-check' || 'fa-credit-card'}",
        text: !!@events.payment_received && @$t('order_timeline_payment_confirmed') || @$t('order_timeline_payment_pending'),
        timestamp: @events.payment_received
      }

    build_order_received_event: ->
      {
        icon: 'fas fa-clipboard-list',
        text: @$t('order_timeline_pending'),
        timestamp: @events.pending
      }

    build_preparing_event: ->
      {
        icon: 'fas fa-clipboard-check',
        text: @$t('order_timeline_preparing'),
        timestamp: @events.preparing
      }

    build_ready_for_dispatch_event: ->
      {
        icon: 'fas fa-box',
        text: !!@requires_shipping && @$t('order_timeline_ready_for_delivery') || @$t('order_timeline_ready_for_collection'),
        timestamp: @events.ready_for_collection || @events.ready_for_delivery
      }

    build_dispatched_event: ->
      {
        icon: 'fas fa-shipping-fast',
        text: @$t('order_timeline_out_for_delivery'),
        timestamp: @events.out_for_delivery
      }

    build_complete_event: ->
      {
        icon: 'fas fa-check',
        text: !!@requires_shipping && @$t('order_timeline_delivered') || @$t('order_timeline_collected'),
        timestamp: @events.delivered || @events.collected
      }

    date_from: (datetime) ->
      DateTime.fromISO(datetime).setZone('Africa/Johannesburg').toFormat('dd LLL yyyy')

    time_from: (datetime) ->
      DateTime.fromISO(datetime).setZone('Africa/Johannesburg').toFormat('T')

  }
}
